import React from "react"
import { Helmet } from "react-helmet"

export default function Head() {
  const url = "https://javascriptsimplified.com"
  const twitterAccount = "@devsimplified"
  const description =
    "Learn how to think like a developer and build any project you can dream of by taking action instead of just following along with tutorials."
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="twitter:site" content={twitterAccount} />
      <meta name="twitter:creator" content={twitterAccount} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta property="og:title" content="JavaScript Simplified" />
      <meta
        name="description"
        property="og:description"
        content={description}
      />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={`${url}/open-graph.jpg`} />
      <meta property="og:type" content="website" />
      <meta property="og:image:width" content="1910" />
      <meta property="og:image:height" content="1000" />
      <title>JavaScript Simplified</title>
      <script
        defer
					event-branch={process.env.BRANCH}
          data-domain={
          process.env.NODE_ENV === 'production'
            ? 'javascriptsimplified.com'
            : 'testing'
        }
        data-api="https://royal-surf-e76e.kyle-0ed.workers.dev/api/e"
        src={`https://royal-surf-e76e.kyle-0ed.workers.dev/js/script.pageview-props.tagged-events${
          process.env.NODE_ENV === 'production' ? '' : '.local'
        }.js`}
      ></script>
      <script>{`window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`}</script>
      <script src="https://cdn.paritydeals.com/banner.js"></script>
      
    </Helmet>
  )
}
